<template>
  <div class="bg-repeat-y bg-cover bg-purple-700 rounded-b-lg shadow shadow-xl shadow-purple-400 ">
    <div class="py-2 ">
      <div class=" items-center m-1 ">
          <van-row>
            <van-col span="2">
              <img src="/images/tx.png" class="w-12 h-12 rounded-full mr-2"/>
            </van-col>
            <van-col span="18" class="text-white text-2xl ml-2">
              <div class="flex align-items-center text-base">
                {{ userOverview ? userOverview.account : '' }}
              </div>
              <div class="flex align-items-center">

                <van-icon name="vip-card" class="text-yellow-500 text-lg" size="28"/>
                <p class="text-lg ml-1 text-yellow-500">VIP{{ userOverview ?userOverview.vipLevel:'' }}</p>
                <p class="text-white text-lg ml-2">{{ userOverview ? userOverview.nickname : '' }}</p>
              </div>
            </van-col>
            <van-col span="3"  >
              <van-button   color="#7a25cb"  to="/settings" icon="setting" size="large" >设置</van-button>
            </van-col>
          </van-row>
      </div>

    </div>
    <div class="w-80 mx-auto pb-10 ">
      <ul class="grid grid-cols-3 gap-4 text-center text-white">
        <li>
          <p class="text-base text-center">{{ userOverview ? (Number(userOverview.balance) / 100).toFixed(2) : '' }}</p>
          <p class="text-sm text-center">余额</p>
        </li>
        <li>
          <p class="text-base text-center">{{ userOverview ? Number(userOverview.totalTaxReturned).toFixed(2) : '' }}</p>
          <p class="text-sm text-center">返还</p>
        </li>
        <li>
          <p class="text-base text-center">{{ userOverview ? userOverview.creditScore : '' }}</p>
          <p class="text-sm text-center">信誉分</p>
        </li>
      </ul>
    </div>
  </div>

  <div class="clear"></div>
  <div class="relative mt-[-2rem] px-4 ">
    <div class="flex overflow-hidden bg-white p-2 rounded-lg shadow-md shadow-gray-300">
      <div class="inline-block w-1/5 text-center cursor-pointer" @click="$router.push('/tabList/0')">
        <div class="block">
          <p class="text-center text-black text-sm">
            {{ userOverview ? Number(userOverview.waitGroupPurchase).toFixed(2) : '' }}</p>
          <p class="text-center text-black text-sm">等待中</p>
        </div>
      </div>
      <div class="inline-block w-1/5 text-center  cursor-pointer" @click="$router.push('/tabList/1')">
        <div class="block">
          <p class="text-center text-black text-sm">
            {{ userOverview ? Number(userOverview.succeedGroupPurchase).toFixed(2) : '' }}</p>
          <p class="text-center text-black text-sm">已购买</p>
        </div>
      </div>
      <div class="inline-block w-1/5 text-center cursor-pointer" @click="$router.push('/tabList/2')">
        <div class="block">
          <p class="text-center text-black text-sm">{{
              userOverview ? Number(userOverview.waitTaxReturn).toFixed(2) : ''
            }}</p>
          <p class="text-center text-black text-sm">申请中</p>
        </div>
      </div>
      <div class="inline-block w-1/5 text-center cursor-pointer" @click="$router.push('/tabList/3')">
        <div class="block">
          <p class="text-center text-black text-sm">
            {{ userOverview ? Number(userOverview.alreadyTaxReturned).toFixed(2) : '' }}</p>
          <p class="text-center text-black text-sm">已返还</p>
        </div>
      </div>
      <div class="inline-block w-1/5 text-center border-l border-gray-300  cursor-pointer"
           @click="$router.push('/fund')">

        <div class="block">
          <p class="text-center text-black text-sm"><img src="../../static/images/my01.png" alt="All Records"
                                                         class="h-[1.2rem] mx-auto"/></p>
          <p class="text-center text-black text-sm">全部记录</p>
        </div>
      </div>
    </div>
  </div>


  <div class="px-4 mt-3 ">
    <div class="bg-white rounded-lg py-2 px-4 shadow-xl shadow-gray-300">
      <div class="flex justify-between items-center border-b border-gray-200 pb-1">
        <p class="text-black font-bold text-sm">我的订单</p>
        <router-link to="/tabList/all" class="text-gray-600 text-xs">查看更多订单 ></router-link>
      </div>
      <ul class="grid grid-cols-5 gap-4 mt-3">
        <li class="text-center">
          <router-link to="/tabList/all" class="block">
            <div class="flex flex-col items-center">
              <van-icon name="bars" class="h-8 w-8 mb-1 text-red-400" size="30"/>
              <p class="text-xs text-gray-600">全部订单</p>
            </div>
          </router-link>
        </li>
        <li class="text-center">
          <router-link to="/tabList/0" class="block">
            <div class="flex flex-col items-center">
              <van-icon name="clock" class="h-8 w-8 mb-1 text-red-400" size="30"/>
              <p class="text-xs text-gray-600">等待中</p>
            </div>
          </router-link>
        </li>
        <li class="text-center">
          <router-link to="/tabList/1" class="block">
            <div class="flex flex-col items-center">
              <van-icon name="/images/shipping.png" class="h-8 w-8 mb-1 text-red-400" size="30"/>
              <p class="text-xs text-gray-600">已购买</p>
            </div>
          </router-link>
        </li>
        <li class="text-center">
          <router-link to="/tabList/2" class="block">
            <div class="flex flex-col items-center">
              <van-icon name="todo-list" class="h-8 w-8 mb-1 text-red-400" size="30"/>
              <p class="text-xs text-gray-600">申请中</p>
            </div>
          </router-link>
        </li>
        <li class="text-center">
          <router-link to="/tabList/3" class="block">
            <div class="flex flex-col items-center">
              <van-icon name="completed" class="h-8 w-8 mb-1 text-red-400" size="30"/>
              <p class="text-xs text-gray-600">已返还</p>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>



  <div class="mx-4 mt-3 bg-white rounded-lg shadow-xl shadow-gray-300">
    <div class="grid grid-cols-4 gap-4 py-4">
      <router-link to="/my/recharge" class="flex flex-col items-center">
        <van-icon name="cash-back-record" class="h-10 mb-2 text-blue-500" size="40" />
        <p class="text-sm text-gray-600">在线充值</p>
      </router-link>
      <router-link to="/rechargeHistory" class="flex flex-col items-center">
        <van-icon name="balance-list" class="h-10 mb-2 text-blue-500" size="40" />
        <p class="text-sm text-gray-600">充值记录</p>
      </router-link>
      <router-link to="/my/message" class="flex flex-col items-center">
        <svg class="h-10 mb-2 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
        </svg>
        <p class="text-sm text-gray-600">我的消息</p>
      </router-link>
      <router-link to="/settings" class="flex flex-col items-center">
        <van-icon name="setting" class="h-10 mb-2 text-gray-700" size="40" />
        <p class="text-sm text-gray-600">个人设置</p>
      </router-link>
      <router-link to="/applywd" class="flex flex-col items-center">
        <van-icon name="card" class="h-10 mb-2 text-red-500" size="40" />
        <p class="text-sm text-gray-600">申请提现</p>
      </router-link>
      <router-link to="/wdHisotry" class="flex flex-col items-center">
        <van-icon name="notes" class="h-10 mb-2 text-red-500" size="40" />
        <p class="text-sm text-gray-600">提现记录</p>
      </router-link>
      <a href="javascript:void(0)" class="flex flex-col items-center">
        <svg class="h-10 mb-2 text-green-600"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />  <polyline points="7 10 12 15 17 10" />  <line x1="12" y1="15" x2="12" y2="3" /></svg>
        <p class="text-sm text-gray-600">APP下载</p>
      </a>
      <router-link to="/customerService" class="flex flex-col items-center" target="_blank">
        <van-icon name="service" class="h-10 mb-2 text-orange-500" size="40" />
        <p class="text-sm text-gray-600">客服</p>
      </router-link>
    </div>
  </div>



  <!-- <div class="mypart4">
      <img src="../../static/images/my15.png" />
  </div> -->
  <van-divider :style="{ color: 'red', borderColor: '#f7f7f7', padding: '0 16px', fontSize: '18px' }">
    <VanIcon name="hot" size="40"/>
    <span class="m-2">热销推荐</span>
    <VanIcon name="hot" size="40"/>

  </van-divider>
  <RecommendProductList class="mt-1"/>
  <div class="kbox"></div>
  <div class="hbox"></div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import {getUserOverview} from '@/api/authed/mine.js'
import RecommendProductList from '@/components/product/RecommendProductList.vue';

const userOverview = ref(null)
const levelImgSrc = ref('')
onMounted(() => {
  getUserOverview().then(res => {
    if (res && res.data) {
      userOverview.value = res.data
      levelImgSrc.value = '/images/vip' + userOverview.value.vipLevel + '.jpg'
    }
  })
})

</script>


<style scoped></style>
