<template>
  <van-sticky>
    <GoBackHeader title="我的订单"/>
    <div class="bg-white">
      <ul class="flex">
        <li :class="{ 'border-b border-solid border-red-500 font-bold': isAll }" class="w-1/5 text-center text-sm">
          <router-link :to="'/tabList/all'" class="block h-full py-2">全部</router-link>
        </li>
        <li :class="{ 'border-b border-solid border-red-500 font-bold': isWait }" class="w-1/5 text-center text-sm">
          <router-link :to="'/tabList/0'" class="block h-full py-2">等待中</router-link>
        </li>
        <li :class="{ 'border-b border-solid border-red-500 font-bold': isSucceed }" class="w-1/5 text-center text-sm">
          <router-link :to="'/tabList/1'" class="block h-full py-2">已购买</router-link>
        </li>
        <li :class="{ 'border-b border-solid border-red-500 font-bold': isWaitTaxReturn }" class="w-1/5 text-center text-sm">
          <router-link :to="'/tabList/2'" class="block h-full py-2">申请中</router-link>
        </li>
        <li :class="{ 'border-b border-solid border-red-500 font-bold': isAlreadyTaxRetuned }" class="w-1/5 text-center text-sm">
          <router-link :to="'/tabList/3'" class="block h-full py-2">已返还</router-link>
        </li>
      </ul>

    </div>
  </van-sticky>
  <div class="px-4 font-bold pb-14">
    <div v-for="(item, index) in groupPurchaseList" :key="index"
         class="bg-white  rounded-md mt-4 shadow-xl">

      <div class="p-2 border-b border-solid border-gray-300 flex justify-between items-center">
        <div class="overflow-hidden ">
          <a href="javascript:void(0)" class="flex items-center">
            <span class="text-sm text-gray-700">订单状态</span>
            <img src="../../static/images/mre1.png" alt="" class="ml-2">
          </a>
        </div>
        <div class="text-red-500 text-sm">
          <a href="#" class="text-red-500">{{ statusFormatter(item.orderStatus) }}</a>
        </div>
      </div>
      <van-steps :active="item.orderStatus" v-if="item.orderStatus !='-1'">
        <van-step>等待中</van-step>
        <van-step>已购买</van-step>
        <van-step>申请中</van-step>
        <van-step>已返还</van-step>
      </van-steps>
      <div class="bg-white border-b mb-1.6 flex  ">

        <div class="w-28 pr-3.2 flex-shrink-0 p-2 pt-2  ">
          <img :src="item.productImg" :alt="item.productName" class="w-28 h-28 object-contain rounded-lg"
               v-lazy="item.productImg"/>
        </div>
        <div class="flex-1 flex flex-col justify-between p-2">
          <div>
            <div class="text-sm pt-2 overflow-hidden line-clamp-2 text-ellipsis line-clamp-2 leading-4.8 text-gray-800">
              {{ item.productName }}
            </div>
            <div class="mt-2">
              <span class="text-sm border border-red-500 text-red-500 py-0.8 px-2 rounded-md bg-white">积分: {{
                  item.taxReturned.toFixed(2)
                }}</span>
            </div>
          </div>
          <div class="flex justify-left items-center">
            <span class="text-base text-red-500 font-semibold">￥{{ item.groupPrice }}</span>
            <span class="text-sm ml-4 text-gray-500 line-through">￥{{ item.marketPrice }}</span>

          </div>
        </div>
      </div>
      <div class="p-2  border-solid border-gray-300 ">
        <p class="text-sm  text-gray-700 text-right">共一件商品，合计:<span class="text-red-500">￥{{
            item.orderStatus === 3 ? (item.orderAmount + item.taxReturned).toFixed(2) : (item.orderAmount).toFixed(2)
          }}</span></p>
      </div>
      <div class="px-4 pb-2 flex justify-end" v-if="item.orderStatus === 1">
          <button type="button" @click="applyTaxReturnRow(item.id)" class="py-4 px-2 inline-flex items-center gap-x-2 text-sm font-bold rounded border border-transparent bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
            申请返还
          </button>
      </div>
    </div>
  </div>

</template>

<script setup>
import {ref, onMounted, computed, watch} from 'vue'
import {getGroupPurchaseList, applyTaxReturn} from '@/api/authed/mine.js'
import {showToast} from 'vant'
import {useRoute} from 'vue-router'
import GoBackHeader from "@/components/common/GoBackHeader.vue";

const groupPurchaseList = ref([])
const route = useRoute()
const status = ref('')
status.value = route.params.status

if (status.value == 'all') {
  status.value = ''
}

const isAll = computed(() => route.path === '/tabList/all')
const isWait = computed(() => route.path === '/tabList/0')
const isSucceed = computed(() => route.path === '/tabList/1')
const isWaitTaxReturn = computed(() => route.path === '/tabList/2')
const isAlreadyTaxRetuned = computed(() => route.path === '/tabList/3')


onMounted(() => {
  getGroupPurchaseList(status.value).then(res => {
    if (res && res.data) {
      groupPurchaseList.value = res.data
    }
  })
})

watch(() => route.path, () => {
  status.value = route.params.status
  if (status.value == 'all') {
    status.value = ''
  }
  getGroupPurchaseList(status.value).then(res => {
    if (res && res.data) {
      groupPurchaseList.value = res.data
    }
  })
})


const applyTaxReturnRow = (id) => {
  applyTaxReturn(id).then(res => {
    if (res && res.code == 200) {
      showToast({
        message: '申请返还成功',
        duration: 1000
      });
      getGroupPurchaseList(status.value).then(res => {
        if (res && res.data) {
          groupPurchaseList.value = res.data
        }
      })
    }
  })
}

const statusFormatter = (value) => {
  if (value == '0') {
    return '等待中'
  }
  if (value == '1') {
    return '已购买'
  }

  if (value == '2') {
    return '申请中'
  }

  if (value == '3') {
    return '已返还'
  }
  if (value == '-1') {
    return '已失效'
  }
}

</script>

